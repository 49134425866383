<template>
  <v-container>
    <!-- BOC:[breadcrumbs] -->
    <ABreadcrumb :items="breadcrumbs"></ABreadcrumb>
    <!-- EOC -->
    <!-- BOC:[error] -->
    <AError
      v-if="api.isError"
      :api="api"
      :callbackReset="() => (api.isError = false)"
    ></AError>
    <!-- EOC -->
    <v-card class="mx-auto pa-3" max-width="800" outlined min-height="200">
      <v-card-title>Change Admin</v-card-title>

      <div class="d-flex align-center justify-space-around">
        <div class="button-content text-center">
          <v-form ref="formName" v-model="valid" lazy-validation>
            <div class="text-center pt-3">
              <SelectField
                :value="accountId"
                :field="selectField"
                @input="accountId = $event"
                label="Teacher"
              ></SelectField>
            </div>
          </v-form>
        </div>
      </div>
      <div class="d-flex align-center justify-space-around">
        <div class="button-content text-center">
          <div class="text-center pt-1">
            <v-btn
              width="75%"
              :loading="api.isLoading"
              rounded
              color="primary"
              dark
              @click="submit()"
            >
              Submit
            </v-btn>
          </div>
          <div class="text-center pt-2">
            <v-btn
              width="75%"
              rounded
              outlined
              color="primary"
              dark
              @click="$router.go(-1)"
            >
              Cancel
            </v-btn>
          </div>
        </div>
      </div>
    </v-card>
  </v-container>
</template>
    
    <script>
import { mapState } from "vuex";
import SelectField from "../../components/Form/SelectField.vue";
export default {
  components: {
    SelectField,
  },
  computed: mapState({
    //
  }),
  props: [
    //
  ],
  data: () => ({
    valid: true,
    url: null,
    //BOC:[breadcrumbs]
    breadcrumbs: [],
    //EOC
    selectField: {
      defaultValue: "",
      name: "Teacher",
      options: [],
      optionText: "User.name",
      optionValue: "User.accountId",
    },
    accountId: null,
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    //BOC:[api]
    teacherApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
  }),
  watch: {},
  created() {
    //BOC:[breadcrumbs]
    this.breadcrumbs = [
      {
        text: "Dashboard",
        to: { name: "PageEducatorDashboard" },
        exact: true,
      },
      {
        text: "My Institution",
        to: {
          name: "PageEducatorInstitutionDashboard",
          params: { id: this.$route.params.id },
        },
        exact: true,
      },
      {
        text: "Teachers",
        to: {
          name: "PageEducatorInstitutionTeacher",
          params: { id: this.$route.params.id },
        },
        exact: true,
      },
      {
        text: "Teacher",
        to: {
          name: "PageEducatorInstitutionTeacherRead",
          params: { 
            id: this.$route.params.id,
            parentId: this.$route.params.id,
          },
        },
        exact: true,
      },
      {
        text: "Change Admin",
        to: {
          name: "PageEducatorInstitutionTeacherChangeAdmin",
          params: { 
            id: this.$route.params.id,
            parentId: this.$route.params.id,
          },
        },
        exact: true,
      },
    ]
    //EOC
    this.teacherApi.method = "GET";
    this.teacherApi.url =
      this.$api.servers.sso +
      "/api/v1/" +
      this.$_getLocale() +
      "/educator/institution/" +
      this.$route.params.id +
      "/teacher/joined";

    this.teacherApi.callbackReset = () => {
      this.teacherApi.isLoading = true;
      this.teacherApi.isError = false;
    };

    this.teacherApi.callbackError = (e) => {
      this.teacherApi.isLoading = false;
      this.teacherApi.isError = true;
      this.teacherApi.error = e;
    };

    this.teacherApi.callbackSuccess = (resp) => {
      this.teacherApi.isLoading = false;
      this.selectField.options = resp;
      const adminTeacher = this.$_.find(resp, (t) => {
        return this.$_.isEqual(t.User.accountId, t.Institution.accountId);
      });
      this.accountId = adminTeacher.User.accountId;
    };

    this.api.url =
      this.$api.servers.sso +
      "/api/v1/" +
      this.$_getLocale() +
      "/educator/institution/changeAdmin";
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
      this.api.error = null;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = () => {
      this.api.isLoading = false;
      this.$router.push({
        name: "PageEducatorInstitutionTeacher",
        params: { id: this.$route.params.id },
      });
    };
  },
  mounted() {
    this.$api.fetch(this.teacherApi);
  },
  methods: {
    // updateBreadcrumb(resp) {
    //   this.breadcrumbs.push({
    //     text: "Dashboard",
    //     to: { name: "PageEducatorDashboard" },
    //     exact: true,
    //   });
    //   this.breadcrumbs.push({
    //     text: resp.name,
    //     to: {
    //       name: "PageEducatorInstitutionDashboard",
    //       params: { id: this.$route.params.id },
    //     },
    //     exact: true,
    //   });

    //   this.breadcrumbs.push({
    //     text: "Teachers",
    //     to: {
    //       name: "PageEducatorInstitutionTeacher",
    //       params: { id: this.$route.params.id },
    //     },
    //     exact: true,
    //   });
    //   this.breadcrumbs.push({
    //     text: "Change Admin",
    //     to: { name: "PageEducatorInstitutionTeacherChangeAdmin" },
    //     exact: true,
    //   });
    // },
    close() {
      this.$router.push({
        name: "PageEducatorInstitutionTeacher",
        params: { id: this.$route.params.id },
      });
    },
    submit() {
      this.api.params = this.$_.clone({
        id: this.$route.params.id,
        accountId: this.accountId,
      });
      this.$api.fetch(this.api);
    },
  },
};
</script>
<style scoped>
.menu-item {
  border: 1px solid black;
  margin: 3px;
}
.form-content {
  width: 800px !important;
}
.button-content {
  width: 300px !important;
}

.contant-or {
  min-width: 100px;
  text-align: center;
  border-bottom: 1px solid #000;
  line-height: 0.1em;
  margin: 10px 0 20px;
}

.contant-or span {
  background: #fff;
  padding: 0 10px;
}
</style>